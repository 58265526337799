import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';

import { type DtoGamePackUGCFile } from '@lp-lib/api-service-client/public';
import { type Media, MediaType } from '@lp-lib/media';

import { UGCUtils } from '../components/Game/UGC';
import { NewWindowIcon } from '../components/icons/NewWindowIcon';
import { apiService } from '../services/api-service';
import { fromMediaDTO } from '../utils/api-dto';
import { assertExhaustive } from '../utils/common';
import { DateUtils } from '../utils/date';
import { MediaUtils } from '../utils/media';
import { tokenWithRedirect } from '../utils/router';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });

  const [packResp, ugcFilesResp] = await Promise.all([
    tokenWithRedirect(
      () => apiService.gamePack.getGamePackById(id),
      action.request.url,
      { admin: true }
    ),
    tokenWithRedirect(
      () => apiService.gamePack.getUGCFiles(id),
      action.request.url,
      { admin: true }
    ),
  ]);

  return {
    pack: packResp.data.gamePack,
    ugcFiles: ugcFilesResp.data.files,
  };
}

function MediaPreview(props: { fileName: string; media: Media }) {
  const { fileName, media } = props;

  switch (media.type) {
    case MediaType.Image:
      return (
        <img
          src={MediaUtils.PickMediaUrl(media) || ''}
          alt='preview'
          className='w-full h-full rounded-lg object-contain'
        />
      );
    case MediaType.Video:
      return (
        <video
          src={media.url}
          className='w-full h-full rounded-lg object-contain'
          preload={'none'}
          poster={media.firstThumbnailUrl ?? ''}
        />
      );
    case MediaType.Audio:
      return (
        <div className='w-full h-full rounded-lg border border-secondary flex items-center justify-center text-sms'>
          {fileName}
        </div>
      );
    default:
      assertExhaustive(media.type);
      return null;
  }
}

function UGCFileInfo(props: { file: DtoGamePackUGCFile }) {
  const { file } = props;

  return (
    <tr className='w-full h-10 text-sms hover:bg-lp-gray-002 odd:bg-lp-gray-001'>
      <td
        className={`${file.downloadable ? `cursor-pointer` : ''}`}
        onClick={() => UGCUtils.DownloadFile(file, false)}
      >
        <div className='flex items-center gap-1'>
          <p>{file.name}</p>
          {file.downloadable ? <NewWindowIcon /> : null}
        </div>
      </td>
      <td>{file.status}</td>
      <td>
        {file.media?.media ? (
          <div
            className='w-24 my-1 cursor-pointer'
            style={{ aspectRatio: '16/9' }}
            onClick={() => UGCUtils.DownloadFile(file, true)}
          >
            <MediaPreview
              fileName={file.name}
              media={fromMediaDTO(file.media.media)}
            />
          </div>
        ) : (
          'N/A'
        )}
      </td>
      <td>{file.extFileId ?? 'N/A'}</td>
      <td>{DateUtils.FormatDatetime(file.updatedAt)}</td>
    </tr>
  );
}

export function Component() {
  const { pack, ugcFiles } = useLoaderData<typeof clientLoader>();
  return (
    <div className='w-full relative text-white p-10'>
      <header className='flex justify-between items-center'>
        <h1 className='font-bold text-3xl'>UGC Files for {pack.name}</h1>
      </header>
      <table className='w-full my-10'>
        <thead className='text-left h-12 text-base text-bold'>
          <tr>
            <th>File Name (Download File/Script)</th>
            <th>Status</th>
            <th>Media</th>
            <th>OpenAI FileID</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {ugcFiles.map((file) => (
            <UGCFileInfo key={file.id} file={file} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
